import React from "react"

import Seo from '../components/Seo'
import Tabs from "../components/Tabs"
import TabContent from "../components/TabContent"
import TextContainer from "../components/TextContainer"
import CourseLayout from "../components/CourseLayout"
import TabsContainer from "../components/TabsContainer"

import * as data from "../courseData"

export default function Course() {
  const { title, hours, price, name, id } = data.basic
  return (
    <CourseLayout
      title={title.main}
      subtitle={title.second}
      hours={hours}
      grossPrice={price.gross}
      newGrossPrice={price.new_gross}
      netPrice={price.net}
      name={name}
      id={id}
    >
      <Seo title="Kurs podstawy księgowości" description={`Kurs księgowości od podstaw we Wrocławiu od ${price.gross} zł - dużo zajęć praktycznych.`} />
      <TextContainer>
        <h3>Kurs podstawy księgowości</h3>
        <p>
          Kurs księgowość od podstaw adresowany jest do osób, które dotychczas
          nie pracowały w księgowości, a chciałyby związać swoją przyszłość z
          zawodem księgowego.
        </p>
        <p>
          Na kursie księgowości od podstaw zostaną przedstawione szczegółowo nie
          tylko podstawowe zagadnienia oraz pojęcia wykorzystywane w
          rachunkowości ale również zasady dotyczące podatku VAT, podatków
          dochodowych oraz środków trwałych.
        </p>
        <h3>Dla kogo?</h3>
        <ul>
          <li>wcześniej nie pracowałeś w księgowości</li>
          <li>
            nie posiadasz doświadczenia a wiążesz swoją przyszłość z zawodem
            księgowego
          </li>
          <li>chcesz poznać pojęcia związane z rachunkowością</li>
          <li>chcesz poznać od podstaw zagadnienia rachunkowości</li>
          <li>chcesz poznać zasady dotyczące podatku dochodowego</li>
          <li>chcesz poznać zasady ewidencji środków trwałych</li>
          <li>chcesz sporządzać rozliczenia podatkowe oraz deklaracje VAT</li>
          <li>uczyć się głównie poprzez praktyczne ćwiczenia</li>
          <li>
            uczyć się korzystając z programu finansowo-księgowego Symfonia oraz
            Symfonia
          </li>
          <li>Środki Trwałe</li>
        </ul>

        <h3>Program Symfonia – ćwiczenia praktyczne</h3>
        <p>
          Ponieważ szczególny nacisk kładziemy na praktykę, połowę czasu trwania
          kursu stanowią zajęcia praktyczne polegające na pracy z programem
          Finansowo-Księgowym “Symfonia” oraz “Symfonia” środki trwałe. Zajęcia
          komputerowe swym zakresem obejmują założenie firmy w programie FK
          „Symfonia” oraz księgowanie różnych operacji gospodarczych w okresie
          dwóch miesięcy. Księgowania odbywają się na podstawie sporządzonych na
          zajęciach dokumentów oraz otrzymanych materiałów ćwiczeniowych.
          Dokumenty dotyczą typowych zdarzeń gospodarczych występujących w
          firmach oraz wyjątków spotykanych w księgowości. Każdy uczestnik kursu
          pracuje na osobnym komputerze przez cały czas trwania kursu. Przy
          użyciu rzutnika prowadzący zajęcia zapoznaje uczestników z programami
          księgowymi używanymi na zajęciach oraz pokazuje sposób księgowania
          danej operacji gospodarczej, którą wszyscy księgują na swoich
          komputerach. Po zaksięgowaniu miesiąca uczestnicy sprawdzają
          prawidłowość swoich księgowań, poprawiają błędy oraz sporządzają
          różnego rodzaju zestawienia oraz deklaracje podatkowe
        </p>
        <h3>Materiały</h3>
        <p>
          Każdy uczestnik kursu otrzymuje płytę CD z aktualnymi aktami prawnymi
          w formie pdf oraz wersjami demonstracyjmymi używanych programów
          księgowych. Programy „Symfonia” można zainstalować w domu, następnie
          odtworzyć sporządzoną na zajęciach kopię bazy danych i ćwiczyć
          księgowanie operacji gospodarczych samodzielnie. Wykłady na zajęciach
          teoretycznych prowadzone są w oparciu o autorsie materiały, ok.60
          stron, które uczestnicy otrzymują w formie zbroszurowanej.
        </p>
        <h3>Egzamin</h3>
        <p>
          Na ostatnich zajęciach przeprowadzany jest egzamin praktyczny
          polegający na samodzielnym księgowaniu operacji gospodarczych w
          programie FK „Symfonia”.
        </p>
        <TabsContainer>
          <Tabs elements={["Program kursu", "Harmonogram kursu"]}>
            <TabContent index={0}>
              <h3>Program kursu podstawy księgowości</h3>
              <ul>
                <li>Rachunkowość jako system ewidencji gospodarczej</li>
                <li>
                  Podmioty zobligowane do stosowania przepisów ustawy o
                  rachunkowości
                </li>
                <li>Zasady rachunkowości</li>
                <li>Charakterystyka aktywów</li>
                <li>Charakterystyka pasywów</li>
                <li>Konta podział i funkcjonowanie</li>
                <li>Typy operacji gospodarczych</li>
                <li>Dokumentacja operacji gospodarczych</li>
                <li>
                  Środki trwałe, klasyfikacja, ewidencja, umorzenie, amortyzacja
                </li>
                <li>Układ rodzajowy kosztów</li>
                <li>Podatek VAT istota, regulacje prawne</li>
                <li>Ogólne zasady VAT zawarte w przepisach prawnych</li>
                <li>Zasady wystawiania dokumentów VAT</li>
                <li>Rejestry oraz deklaracje VAT</li>
                <li>Rozrachunki podmiotów krajowych</li>
                <li>
                  Podatek dochodowy od osób prawnych, zakres podmiotowy,
                  przychody opodatkowane oraz zwolnione, koszty uzyskania
                  przychodów, podstawa opodatkowania
                </li>
                <li>
                  Podatek dochodowy od osób fizycznych, zakres podmiotowy,
                  przychody opodatkowane oraz zwolnione, koszty uzyskania
                  przychodów, podstawa opodatkowania
                </li>
                <li>Rozliczenia z pracownikami oraz budżetem</li>
                <li>Sporządzanie rozliczeń podatkowych</li>
              </ul>
            </TabContent>
            <TabContent index={1}>
              <h3>Harmonogram kursu podstawy księgowości</h3>
              <h5>Zajęcia nr 1 i 2</h5>
              <p>
                Tematy zajęć: wprowadzenie do rachunkowości, różne formy
                prowadzenia księgowości, zasady rachunkowości, bilans , rachunek
                zysków i strat, klasyfikacja aktywów i pasywów, zasady
                funkcjonowania kont, tworzenie nowej firmy w programie
                księgowym, modyfikowanie planu kont, wprowadzanie bilansu
                otwarcia do programu księgowego.
              </p>
              <h5>Zajęcia nr 3 i 4</h5>
              <p>
                Tematy zajęć: wprowadzanie dokumentów do programu księgowego,
                podatek VAT teoria, wystawianie i księgowanie dokumentów VAT w
                programie księgowym, sporządzanie deklaracji VAT
              </p>
              <h5>Zajęcia nr 5 i 6</h5>
              <p>
                Tematy zajęć: księgowanie w programie księgowym wyciągów
                bankowych i raportów kasowych, podatki dochodowe teoria,
                sporządzanie i księgowanie delegacji, listy płac, rozliczenia
                zaliczki
              </p>
              <h5>Zajęcia nr 7 i 8</h5>
              <p>
                Tematy zajęć: środki trwałe teoria, wprowadzanie środków
                trwałych do ewidencji, księgowanie na kontach, naliczanie
                amortyzacji, środki trwałe w budowie, likwidacja środków
                trwałych, leasing operacyjny i finansowy
              </p>
              <h5>Zajęcia nr 9 i 10</h5>
              <p>
                Tematy zajęć: ustalanie wyniku bilansowego oraz podatkowego,
                obliczanie zaliczek na podatek dochodowy, powtórzenie
                wiadomości, egzamin.
              </p>
            </TabContent>
          </Tabs>
        </TabsContainer>
      </TextContainer>
    </CourseLayout>
  )
}
